
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Slider",
  props: {
    min: Number,
    max: Number,
    step: Number,
    pluralize: String,
    defaultValue: Number,
    answerKey: String,
  },
  setup(props) {
    const value = ref(props.defaultValue);

    return { value };
  },
  methods: {
    setValues() {
      this.$emit("getValue", {
        value: this.value,
        valid: true,
        answerKey: this.answerKey,
      });
    },
  },
});
