import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-group mb-2" }
const _hoisted_2 = ["min", "step", "max"]
const _hoisted_3 = { class: "h5 font-weight-bold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        class: "range",
        min: _ctx.min,
        step: _ctx.step,
        max: _ctx.max,
        type: "range",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        name: "loan",
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setValues && _ctx.setValues(...args)))
      }, null, 40, _hoisted_2), [
        [_vModelText, _ctx.value]
      ])
    ]),
    _createElementVNode("label", _hoisted_3, [
      (_ctx.pluralize === 'amount')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", null, "$" + _toDisplayString(_ctx.$filters.pluralizeAmount(_ctx.value)), 1)
          ]))
        : (_ctx.pluralize === 'months')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$filters.pluralizeMonths(_ctx.value)), 1)
            ]))
          : _createCommentVNode("", true)
    ])
  ]))
}